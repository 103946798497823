const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "init";

import "layouts/basic";

import "pages/auditing/audits/new";
import "pages/auditing/audits/show";

import "pages/auth/auth0/new";
import "pages/auth/auth0/retry";
import "pages/auth/auth0/verify";
import "pages/auth/dev_sessions/new";

import "pages/callbacks/callbacks/confirm";
import "pages/callbacks/callbacks/process";

import "pages/cataloging/catalogs/open";

import "pages/errors/custom/show";