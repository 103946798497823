import "./new.pcss";

$(document).ready(function() {

    //--------------------------------------------------
    // Unhide notes on previously answered questions
    // The timeout is used to give the browser time to autofill previous answers if navigating back. Otherwise the
    // function runs before any answers are selected, then the values are autofilled, so nothing gets unhidden.
    setTimeout(function() {
        $('.js-question').toArray().forEach(function(question) {
            let radios = $(question).find('input[type="radio"]');
            let unhide = radios.is(':checked');
            if (unhide == true) {
                let note = $(question).find('.js-note')
                note.css('visibility', 'visible');
            }
        });
    }, 100);

    //--------------------------------------------------
    // Advance form when question is answered
    $('.js-answer').click(function(){
        var curQuestionIndex = $(this).attr('data-qidx');

        // unhide note field
        var note = $('.js-note#note-'+curQuestionIndex);
        note.css('visibility', 'visible');

        // and scroll the question to the top of the page
        $('html, body').animate({scrollTop : $('.js-question#question-'+curQuestionIndex).offset().top}, 500);
    })

    //--------------------------------------------------
    // Prevent inadvertent form submission when entering notes and just defocus instead
    $(document).on("keydown", "form", function(event) {
        if(event.key == "Enter") {
            event.target.blur();
        }

        return event.key != "Enter";
    });

    //--------------------------------------------------
    // Lock form submission
    // This prevents duplicate submissions (e.g. direct uploader submits form after completion, potentially causing
    // duplicate submit requests). Allow unlocking (e.g. when an answer is changed) to allow new submission (e.g.
    // navigating back after submission)

    let formSubmitCount = 0;

    function unlockFormSubmit() {
        formSubmitCount = 0;
    }

    function lockFormSubmit() {
        return formSubmitCount++ == 0;
    }

    $('.js-question, .js-context').click(function() {
        unlockFormSubmit();
        showFormMessage(null);
    });

    //--------------------------------------------------
    // Setup delay to allow direct uploads to finish prior to submitting
    const UPLOAD_DEFAULT = 0;
    const UPLOAD_START = 1;
    const UPLOAD_END = 2;

    let formUploadStatus = UPLOAD_DEFAULT;

    $('.js-audit-form').on('direct-uploads:start', function() {
        console.log('Started direct uploads')
        $('.js-loader').show();
        if (formUploadStatus == UPLOAD_DEFAULT) {
            formUploadStatus = UPLOAD_START;
        }
    });

    $('.js-audit-form').on('direct-uploads:end', function() {
        console.log('Ended direct uploads')
        $('.js-loader').hide();
        if (formUploadStatus == UPLOAD_START) {
            formUploadStatus = UPLOAD_END;
        }
    });

    function isReadyToSubmit() {
        return formUploadStatus == UPLOAD_DEFAULT || formUploadStatus == UPLOAD_END;
    }

    const INITIAL_DELAY_MS = 20;
    const POLLING_DELAY_MS = 250;

    function submitAfterUploads(form, forceDelay = true, delay = INITIAL_DELAY_MS) {
        if (forceDelay || !isReadyToSubmit()) {
            console.log('Delaying submission for ', delay, ' ms...')
            setTimeout(function () {
                submitAfterUploads(form, false, POLLING_DELAY_MS);
            }, delay);
        } else {
            if(lockFormSubmit()) {
                console.log('Submitting form', form)
                form.submit();
            } else {
                console.log('Form locked', form)
                showFormMessage('Form locked')
            }
        }
    }

    //--------------------------------------------------
    // Override default direct upload error message
    $('.js-audit-form').on('direct-upload:error', function (event) {
      event.preventDefault();
      showFormMessage('Connection error during upload')
    });

    //--------------------------------------------------
    // Validate form
    $('.js-audit-form').on('submit', async function(event) {
        event.preventDefault();

        const [valid, message] = await validateSubmit();
        if (valid) {
            console.log('Validated form')
            submitAfterUploads(this);
        } else {
            console.log('Invalid form:', message);
            showFormMessage(message)
        }
    });

    function showFormMessage(message) {
        $(".js-submit-msg").html(message);
    }

    async function validateSubmit() {
        let [valid, message] = validateOnline();
        if (!valid) { return [false, message]; }

        [valid, message] = validateFormContext();
        if (!valid) { return [false, message]; }

        [valid, message] = validateObservations();
        if (!valid) { return [false, message]; }

        [valid, message] = await validateServerStatus();
        if (!valid) { return [false, message]; }

        return [true, null];
    }

    function validateOnline() {
        if (navigator.onLine) { return [true, null]; }
        return [false, 'Connection offline'];
    }

    function validateFormContext() {
        // Note[J5EC7Ukul]: the backend uses 'blank' as the value for an unselected option
        const unselectedValue = 'blank';

        let auditorId = $('select[name="auditor_id"] option:selected').val();
        if (auditorId == unselectedValue) {
            return [false, 'Select an auditor'];
        }

        let subjectId = $('select[name="subject_id"] option:selected').val();
        if (subjectId == unselectedValue) {
            return [false, 'Select a subject'];
        }

        let targetId = $('select[name="target_id"] option:selected').val();
        if (targetId == unselectedValue) {
            return [false, 'Select a target'];
        }

        let areaId = $('select[name="area_id"] option:selected').val();
        if (areaId == unselectedValue) {
            return [false, 'Select an area'];
        }

        return [true, null];
    }

    function validateObservations() {
        let questions = $('.js-question');
        let atLeastOneAnswered = questions.toArray().some(function(question) {
            let radios = $(question).find('input[type="radio"]');
            return radios.is(':checked');
        });

        if (atLeastOneAnswered) { return [true, null]; }

        return [false, 'At least one observation required'];
    }

    async function validateServerStatus() {
        const currentDomain = window.location.origin;
        const statusEndpoint = `${currentDomain}/status`;

        try {
            const response = await fetch(statusEndpoint, {
                method: 'GET',
                mode: 'same-origin',
            });

            if (response.status === 200) {
                return [true, null];
            } else {
                console.error('Server is unavailable:', response);
                return [false, 'Unable to submit'];
            }
        } catch (error) {
            console.error('Error:', error);
            return [false, 'Connection error'];
        }
    }
});